import React from "react";
import { Pie } from "react-chartjs-2";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { getColor } from "../src/support/utils";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DotPattern from "./DotPattern";

const existPositionOnList = (position, list) => {
  const items = list.map(({ position }) => {
    return position;
  });

  return items.includes(position);
};

const getPositionIndexOnList = (position, list) => {
  return list.findIndex((item) => item.position === position);
};

const getDisplayByTime = (value) => {
  const SECONDS_IN_DAY = 86400;

  if (value === SECONDS_IN_DAY) {
    return "24:00:00";
  }

  const today = moment().startOf("day");
  const time = moment(today).add(value, "seconds");

  return time.format("HH:mm:ss");
};

const mergeOverview = (overview) => {
  const SECONDS_IN_DAY = 86400;
  let totalSeconds = 0;
  const results = [];

  overview.forEach((item) => {
    if (existPositionOnList(item.position, results)) {
      const positionIndex = getPositionIndexOnList(item.position, results);
      const historicItem = results[positionIndex];
      historicItem.time += item.time;
      historicItem.display = getDisplayByTime(historicItem.time);

      results[positionIndex] = historicItem;
    } else {
      results.push(item);
    }
    totalSeconds += item.time;
  });

  if (totalSeconds !== SECONDS_IN_DAY) {
    const difference = SECONDS_IN_DAY - totalSeconds;
    const semSinalIndex = results.findIndex(
      (item) => item.position === "Sem sinal"
    );

    if (semSinalIndex !== -1) {
      results[semSinalIndex].time += difference;
      results[semSinalIndex].display = getDisplayByTime(
        results[semSinalIndex].time
      );
    } else {
      results.push({
        position: "Sem sinal",
        time: difference,
        display: getDisplayByTime(difference),
      });
    }
  }

  return results;
};

const getDatasets = (list) => {
  const data = list.length > 0 ? list.map((item) => item.time) : [-1];
  const backgroundColor =
    list.length > 0
      ? list.map((item) => getColor(item.position))
      : ["rgb(221, 221, 221)"];
  const dataOriginal = list.length > 0 ? list.map((item) => item.display) : [];

  return [
    {
      data,
      backgroundColor,
      dataOriginal,
      datalabels: {
        formatter(value, context) {
          return "";
        },
        font: {
          size: 12,
          weight: "bold",
        },
        color: "rgba(0,0,0,.6)",
      },
    },
  ];
};

const getLabels = (overview) => {
  if (overview.length === 0) return ["Sem sinal"];

  return overview.map((item) => item.position);
};

const getOverview = (overview) => {
  if (overview.length === 0)
    return [
      {
        position: "Sem sinal",
        time: -1,
        display: "00:00:00",
        color: "rgb(221, 221, 221)",
      },
    ];

  return overview.map((item) => {
    return {
      ...item,
      color: getColor(item.position),
    };
  });
};

export default function DecubitOverview({ overview }) {
  overview = mergeOverview(overview);
  console.log("overview", overview);
  const labels = getLabels(overview);
  const datasets = getDatasets(overview);
  const overviewData = getOverview(overview);

  const options = {
    title: {
      display: false,
      text: "Tempo por posição",
      padding: 24,
      position: "right",
      font: {
        weight: "normal",
      },
      color: "#333333",
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  };
  const data = {
    labels,
    datasets,
  };
  const plugins = [ChartDataLabels];

  const args = {
    plugins,
    options,
    data,
  };

  const hasSuspendedOrPaused =
    overviewData &&
    overviewData.some(
      (item) => item.position === "Suspenso" || item.position === "Pausado"
    );

  return (
    <div className="c-chart c-decubitOverview">
      <Row className="justify-content-end align-items-center">
        <Col xs={12} sm={7} md={8}>
          <div className="c-chart__container">
            <Pie {...args} />
          </div>
        </Col>
        <Col xs={12} sm={5} md={4} className="mt-4 mt-md-0">
          <h2 className="c-decubitOverview__title">Tempo por posição</h2>
          <div role="listitems" className="c-decubitOverview__listItems">
            {overviewData &&
              overviewData.map((item, index) => (
                <div
                  key={index}
                  role="listitem"
                  className={`${
                    index === 0 ? "mt-4" : "mt-3"
                  } c-decubitOverview__listItem`}
                >
                  <p className="m-0 d-flex justify-content-between align-items-center c-decubitOverview__text">
                    <span
                      className={`mr-2 ${
                        item.position === "Suspenso" ||
                        item.position === "Pausado"
                          ? "c-decubitOverview__paused"
                          : "c-decubitOverview__bullet"
                      } ${
                        item.position === "Suspenso" ? "striped-pattern" : ""
                      } ${
                        hasSuspendedOrPaused &&
                        item.position !== "Suspenso" &&
                        item.position !== "Pausado"
                          ? "ml-33"
                          : ""
                      }`}
                      style={{
                        backgroundColor:
                          item.position !== "Suspenso" ? item.color : "",
                      }}
                    >
                      {item.position === "Pausado" && <DotPattern />}
                    </span>
                    <span className="c-decubitOverview__position flex-fill">
                      {item.position}
                    </span>
                    <span className="fw-bold c-decubitOverview__time">
                      {item.display}
                    </span>
                  </p>
                </div>
              ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}
