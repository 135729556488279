import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import Icon from "../../functions/Icon";
import {
  CalculateAge,
  GetImc,
  dynamicMask,
  userWithoutPermission,
} from "../../functions/utils";
import api from "../../services/api";
import { Show_Alert } from "../../store/actions/alert";
import Colors from "../../styles/Colors";

import "../../styles/global.css";
import "./styles.css";
import BatteryAlertCard from "../../components/BaterryAlertCard";
import VerifyPwWithUser from "./VerifyPwWithUser";

var originalRoom = null;

function AddPatient({ history, ...props }) {
  const {
    handler_new_patient,
    add_patient,
    reload_beacons,
    handler_reload_beacons,
    reload_patients,
    handler_reload_patients,
  } = props;
  const dispatch = useDispatch();
  const userUnit = useSelector((state) => state.userUnit);
  const unit = useSelector((state) => state.unit);
  const user = useSelector((state) => state.user);
  const hospitalPlan = useSelector((state) => state.hospitalPlan);
  const patientSelected = useSelector((state) => state.patientSelected);
  const [showPw, setShowPw] = useState(false);

  // Exibição do modal
  const [show, setShow] = useState(props.show);
  const [loadingBtn, setLoadingBtn] = useState("none");

  const [userChooser, setUserChooser] = useState({
    label: "Selecionar",
    value: 0,
  });

  //Info paciente
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [room, setRoom] = useState(0);
  const [subroom, setSubroom] = useState(0);
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [imc, setImc] = useState("");
  const [dateBirth, setDateBirth] = useState("");
  const [age, setAge] = useState("-");
  const [bed, setBed] = useState({ label: "Selecionar superfície", value: 0 });
  const [comorbidities, setComorbidities] = useState([]);
  const [situationalStates, setSituationalStates] = useState([]);
  const [admissionWound, setAdmissionWound] = useState(null);

  const [listRoom, setListRoom] = useState([]);
  const [listSubroom, setListSubroom] = useState([]);
  const [hasSubroom, setHasSubroom] = useState(false);

  const [listBeds, setListBeds] = useState([]);
  const [otherBed, setOtherBed] = useState("");

  const [listComorbidities, setListComorbidities] = useState([]);
  const [listSituationalStates, setListSituationalStates] = useState([]);
  const [listUsers, setListUsers] = useState([]);

  const [hasOther, setHasOther] = useState(false);
  const [hasOtherSituationalState, setHasOtherSituationalState] =
    useState(false);
  const [newComorbiditie, setNewComobiditie] = useState("");
  const [newSituationalState, setNewSituationalState] = useState("");

  const [listBeacons, setListBeacons] = useState([]);
  const [beacon, setBeacon] = useState({
    label: "Selecionar sensor",
    value: 0,
  });

  useEffect(() => {
    if (add_patient && !show) setShow(true);
  }, [add_patient]);

  useEffect(() => {
    if (!userWithoutPermission(user)) {
      setUserChooser({ label: user.dados.name, value: user.dados.id });
    }
  }, []);

  useEffect(() => {
    ResetFields();
    setHasSubroom(false);
    LoadRoom();
  }, [unit, show]);

  useEffect(() => {
    if (history.location.state?.showAddPatientModal) {
      setShow(true);
    }
  }, [history.location.state]);

  function LoadRoom() {
    api
      .get(`/units/${unit.selecionado?.id}/rooms/not_busy`)
      .then((response) => {
        if (response.status == 200) {
          var array = [];
          response.data.map((item) => {
            if (item.required && !item.has_patient) {
              array.push({ label: item.name, value: item.id });
            } else if (!item.required && item.subrooms.length > 0) {
              array.push({ label: item.name, value: item.id });
            }
          });
          originalRoom = response.data;
          setListRoom(array);
        }
      })
      .catch((error) => {
        console.log("LoadRoom error " + error);
      });
  }

  useEffect(() => {
    function LoadBeacons() {
      api
        .get(`/beacons`, {
          params: {
            pagination: false,
            unit_id: unit.selecionado?.id,
            free: true,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            var array = [];
            response.data.map((item) => {
              if (item.linked?.userUnlink !== null) {
                array.push({ label: item.name, value: item.id });
              }
            });
            setListBeacons(array);
          }
        })
        .catch((error) => {
          console.log("LoadBeacons error " + error);
        });
    }

    LoadBeacons();
  }, [reload_beacons]);

  useEffect(() => {
    function LoadBeds() {
      api
        .get(`/typebeds`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadBeds");
            console.log(response.data);

            var array = [];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListBeds(array);
          }
        })
        .catch((error) => {
          console.log("LoadBeds error " + error);
        });
    }

    LoadBeds();
  }, []);

  useEffect(() => {
    function LoadComorbidities() {
      api
        .get(`/comorbidities`)
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadComorbidities");
            console.log(response.data);

            const comorbiditiesArray = [];
            const situationalStatesArray = [];
            response.data.comorbidities.map((item) => {
              comorbiditiesArray.push({ label: item.name, value: item.id });
            });
            response.data.situationalStates.map((item) => {
              situationalStatesArray.push({ label: item.name, value: item.id });
            });
            setListComorbidities(comorbiditiesArray);
            setListSituationalStates(situationalStatesArray);
          }
        })
        .catch((error) => {
          console.log("LoadComorbidities error " + error);
        });
    }

    LoadComorbidities();
  }, []);

  function HandlerRoom(room) {
    setRoom(room);

    var room = originalRoom.find((item) => item.id == room);
    setHasSubroom(room.required);

    var arraySb = [];
    room.subrooms.map((item) => {
      arraySb.push({ label: item.name, value: item.id });
    });

    setListSubroom(arraySb);
  }

  const colourStyles = {
    multiValue: (styles, { data }) => {
      const color = Colors.brand_blue;
      return {
        ...styles,
        backgroundColor: "#ECF3FF",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: Colors.brand_blue,
      ":hover": {
        backgroundColor: "#ECF3FF",
        color: Colors.brand_blue,
      },
    }),
  };

  useEffect(() => {
    function LoadUsers() {
      api
        .get(`/users`, {
          params: {
            permission_id: "1,2,3",
            unit_id: unit.selecionado.id,
            status: 1,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("LoadUsers");
            console.log(response.data);

            var array = [{ label: "Selecionar", value: 0 }];
            response.data.map((item) => {
              array.push({ label: item.name, value: item.id });
            });
            setListUsers(array);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (user.dados.permission_id == 4) {
      LoadUsers();
    }
  }, []);

  function VerifyFields() {
    let verify = false;

    if (!hasSubroom) {
      if (name != "" && room > 0 && subroom > 0) {
        verify = true;
      }
    } else {
      if (name != "" && room > 0) {
        verify = true;
      }
    }

    if (hasOther) {
      if (newComorbiditie == "") verify = false;
    }

    if (hasOtherSituationalState) {
      if (newSituationalState == "") verify = false;
    }

    return verify;
  }

  function RegisterPatient(userId) {
    setLoadingBtn("flex");
    var arrayComorbidities = [];
    comorbidities.map((item, index) => {
      var obj = {};
      obj.comorbidity_id = item.value;
      arrayComorbidities.push(obj);
    });
    situationalStates.map((item) => {
      if (item.idRelation == undefined) {
        var obj = {};
        obj.comorbidity_id = item.value;
        arrayComorbidities.push(obj);
      }
    });

    if (newComorbiditie != "") {
      arrayComorbidities.push({ name: newComorbiditie });
    }
    if (newSituationalState != "") {
      arrayComorbidities.push({
        name: newSituationalState,
        is_situational: true,
      });
    }

    var weightConvert = weight;
    if (weight.length == 6) {
      var parts = weight.split("");
      if (parts[2] == ",") {
        weightConvert = `${parts[0]}${parts[1]}${parts[3]},${parts[4]}${parts[5]}`;
      }
    }

    // console.log("Name " + name);
    // console.log("number_identifier " + id);
    // console.log("gender " + gender);
    // console.log(
    //   "date_birth " + moment(dateBirth, "DD/MM/YYYY").format("YYYY-MM-DD")
    // );
    // console.log("age " + age.split(" ")[0]);
    // console.log("unit_id " + unit.selecionado.id);
    // console.log(
    //   "height " + height != "" ? parseFloat(height.replace(",", ".")) : ""
    // );
    // console.log(
    //   "weight " + weightConvert != ""
    //     ? parseFloat(weightConvert.replace(",", "."))
    //     : ""
    // );
    // console.log("imc " + imc);
    // console.log("room_id " + subroom > 0 ? subroom : room);
    // console.log("type_bed_id " + bed.value);
    // console.log("user_id " + userChooser.value);
    // console.log("comorbidities " + arrayComorbidities);
    // console.log("admission_wound " + admissionWound);
    api
      .post("/patients", {
        name,
        number_identifier: id,
        gender,
        date_birth: moment(dateBirth, "DD/MM/YYYY").format("YYYY-MM-DD"),
        age: age.split(" ")[0],
        unit_id: unit.selecionado.id,
        height: height != "" ? parseFloat(height.replace(",", ".")) : "",
        weight:
          weightConvert != ""
            ? parseFloat(weightConvert.replace(",", "."))
            : "",
        imc: imc != "" ? imc.replace(",", ".") : "",
        status: 1,
        room_id: subroom > 0 ? subroom : room,
        type_bed_id: bed.value > 0 ? bed.value : "",
        type_bed: otherBed,
        user_id: userId ? userId : userChooser.value,
        comorbidities: arrayComorbidities,
        admission_wound: admissionWound,
        beacon_id: beacon?.value,
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("RegisterPatient with success");
          console.log(response.data);
          setBeacon({
            label: "Selecionar sensor",
            value: 0,
          });
          LoadRoom();
          handler_reload_beacons(true);
          // //Add novo paciente cadastrado na lista de pacientes
          let nr = null;
          if (room > 0 && subroom > 0) nr = response.data.subroom.room.name;
          var obj = {
            id: response.data.id,
            name,
            initials: response.data.initials,
            gender: response.data.gender,
            room: nr,
            subroom:
              room == 0
                ? response.data.subroom.room.name
                : response.data.subroom.name,
            qtd_wounds: 0, //Como é novo não vai ter lesão ainda
            qtd_wounds_confirmed: 0,
            qtd_wounds_notification: 0,
            unit_id: response.data.unit_id,
            score: 0,
            score_description: "Sem avaliação de risco",
          };

          handler_new_patient(obj);
          handler_reload_patients(!reload_patients);
          dispatch(
            Show_Alert({
              type: "success",
              msg: "Paciente cadastrado com sucesso",
            })
          );

          //Se cadastrou com lesão, redireciona pro perfil do paciente direto na tab de lesões
          if (admissionWound == 1) {
            dispatch({ type: "SET_PATIENT_SELECTED", dados: obj });
            dispatch({ type: "SET_PATIENT_TAB", selected: 1 });
            history.push({
              pathname: "/Pacientes/Perfil-Paciente",
              state: { withWound: true },
            });
          }
        }
      })
      .catch((error) => {
        console.log("RegisterPatient error " + error);

        dispatch(
          Show_Alert({
            type: "error",
            msg: "Não foi possível cadastrar o paciente. Tente novamente mais tarde",
          })
        );
      })
      .finally(() => {
        handler_reload_beacons(false);
        setLoadingBtn("none");
        setShow(false);
      });
  }

  function ResetFields() {
    setName("");
    setId("");
    setGender("");
    setDateBirth("");
    setAge("-");
    setHeight("");
    setWeight("");
    setImc("");
    setRoom(0);
    setSubroom(0);
    setBed({ label: "Selecionar superfície", value: 0 });
    setOtherBed("");
    setComorbidities([]);
    setSituationalStates([]);
    setAdmissionWound(null);
    setHasSubroom(false);
    setHasOther(false);
    setNewComobiditie("");
    setHasOtherSituationalState(false);
    setNewSituationalState("");
  }

  function FilterOther(list) {
    return list.filter((l) => l.label != "Outros");
  }

  return (
    <>
      {showPw && (
        <VerifyPwWithUser
          handler_show_pw={setShowPw}
          show_pw={showPw}
          modal_name={"Autenticar confirmação"}
          handler_request={RegisterPatient}
        />
      )}
      <Col
        xs={12}
        md={4}
        lg={3}
        className="mt-sm-3 mt-md-0 d-flex justify-content-end"
        onClick={() => {
          setShow(true);
          handler_reload_beacons(!reload_beacons);
        }}
      >
        <div style={{ width: "fit-content" }} className="default-btn mb-0">
          Novo paciente
        </div>
      </Col>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="p-0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
              paddingBottom: 6,
            }}
          >
            <div>
              <div className="modal-title">Novo paciente</div>
            </div>
            <div>
              <div className="modal-close-btn" onClick={() => setShow(false)}>
                <Icon color={Colors.darkest_gray} size={15} icon="x" />
              </div>
            </div>
          </div>

          <div className="modal-all">
            <div className="header-discharge mt-3">
              <div className="mb-4">
                <Form.Label className="field-label">Atendimento</Form.Label>
                <input
                  className="default-input"
                  type="text"
                  placeholder="Digite o atendimento do paciente"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
              </div>

              <div className="mb-4">
                <Form.Label className="field-label">
                  Nome
                  {["HSL", "HMV"].includes(process.env.REACT_APP_HOSPITAL)
                    ? " (iniciais) "
                    : " "}
                  *
                </Form.Label>
                <input
                  className="default-input"
                  type="text"
                  placeholder={
                    ["HSL", "HMV"].includes(process.env.REACT_APP_HOSPITAL)
                      ? "Digite as iniciais do nome do paciente"
                      : "Digite o nome do paciente"
                  }
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <Form.Label className="field-label w-100">Sexo</Form.Label>
                <button
                  style={{
                    background:
                      gender == "M" ? Colors.brand_blue : Colors.lightest_gray,
                  }}
                  className="btn-click"
                  onClick={() => setGender("M")}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: gender == "M" ? "white" : Colors.darker_gray,
                    }}
                  >
                    Masculino
                  </p>
                </button>

                <button
                  style={{
                    background:
                      gender == "F" ? Colors.brand_blue : Colors.lightest_gray,
                  }}
                  className="btn-click"
                  onClick={() => setGender("F")}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: gender == "F" ? "white" : Colors.darker_gray,
                    }}
                  >
                    Feminino
                  </p>
                </button>
              </div>

              <Row className="mt-2">
                <Col className="mb-3 mb-md-0" col={12} md={6}>
                  <Form.Label className="field-label">Quarto *</Form.Label>
                  <Select
                    captureMenuScroll={false}
                    isSearchable={false}
                    noOptionsMessage={() => "Indisponível"}
                    classNamePrefix="react-select"
                    placeholder={"Selecionar"}
                    options={listRoom}
                    isClearable={false}
                    onChange={(item) => HandlerRoom(item.value)}
                  />
                </Col>

                {!hasSubroom && (
                  <Col className="mb-3 mb-md-0" col={12} md={6}>
                    <Form.Label className="field-label">Leito *</Form.Label>
                    <Select
                      captureMenuScroll={false}
                      isSearchable={false}
                      noOptionsMessage={() => "Indisponível"}
                      classNamePrefix="react-select"
                      placeholder={"Selecionar"}
                      options={listSubroom}
                      isClearable={false}
                      onChange={(item) => setSubroom(item.value)}
                    />
                  </Col>
                )}
              </Row>

              <div className="mt-4">
                <Form.Label className="field-label w-100">
                  Admitido com lesão por pressão?
                </Form.Label>
                <button
                  style={{
                    background:
                      admissionWound == 1
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                    width: 100,
                  }}
                  className="btn-click"
                  onClick={() => setAdmissionWound(1)}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: admissionWound == 1 ? "white" : Colors.darker_gray,
                    }}
                  >
                    Sim
                  </p>
                </button>

                <button
                  style={{
                    background:
                      admissionWound == 0
                        ? Colors.brand_blue
                        : Colors.lightest_gray,
                    width: 100,
                  }}
                  className="btn-click"
                  onClick={() => setAdmissionWound(0)}
                >
                  <p
                    className="mb-0"
                    style={{
                      color: admissionWound == 0 ? "white" : Colors.darker_gray,
                    }}
                  >
                    Não
                  </p>
                </button>
              </div>

              {hospitalPlan.dados.type === 1 && (
                <div className="mt-4">
                  <Form.Label className="field-label">Sensor</Form.Label>
                  <Select
                    isSearchable={false}
                    menuPlacement="auto"
                    captureMenuScroll={false}
                    noOptionsMessage={() => "Sem opções"}
                    classNamePrefix="react-select"
                    placeholder={"Selecionar sensor"}
                    options={listBeacons}
                    isClearable={false}
                    onChange={(item) => setBeacon(item)}
                  />
                  <div className="card-correct">
                    <BatteryAlertCard
                      unitId={
                        patientSelected.dados.unit_id || unit.selecionado?.id
                      }
                      beaconId={beacon.value}
                    />
                  </div>
                </div>
              )}

              <Row className="mt-4">
                <Col xs={12} md={4}>
                  <Form.Label className="field-label">Peso (kg)</Form.Label>
                  <InputMask
                    maskChar={null}
                    className="default-input"
                    type="text"
                    placeholder="00,00"
                    value={weight}
                    beforeMaskedStateChange={dynamicMask}
                    onChange={(e) => {
                      setWeight(e.target.value);
                      console.log(e.target.value);
                      setImc(GetImc(e.target.value, height));
                    }}
                  />
                </Col>

                <Col xs={12} md={4}>
                  <Form.Label className="field-label">Altura (m)</Form.Label>
                  <InputMask
                    mask="9,99"
                    maskChar={null}
                    className="default-input"
                    type="text"
                    placeholder="0,00"
                    value={height}
                    onChange={(e) => {
                      setHeight(e.target.value);
                      setImc(GetImc(weight, e.target.value));
                    }}
                  />
                </Col>

                <Col xs={12} md={4}>
                  <Form.Label className="field-label">IMC</Form.Label>
                  <InputMask
                    disabled={true}
                    mask="99,99"
                    maskChar={null}
                    style={{
                      background: "#468DFF1A",
                      color: Colors.brand_blue,
                      border: "none",
                      fontWeight: "bold",
                    }}
                    className="default-input text-center input-imc"
                    type="text"
                    placeholder="00,00"
                    value={imc}
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12} md={8}>
                  <Form.Label className="field-label">
                    Data de nascimento
                  </Form.Label>
                  <div className="d-flex align-items-center default-input">
                    <Icon
                      color={Colors.darker_gray}
                      size={24}
                      icon="calendar"
                      className="mr-2"
                    />
                    <InputMask
                      mask={"99/99/9999"}
                      maskChar={null}
                      style={{ border: "none" }}
                      type="text"
                      placeholder="DD/MM/AAAA"
                      value={dateBirth}
                      onChange={(e) => {
                        let dateBirth = e.target.value;
                        setDateBirth(dateBirth);
                        if (dateBirth.length == 10) {
                          if (!CalculateAge(dateBirth)) {
                            alert("Data inválida");
                            setDateBirth("");
                            return false;
                          }
                          setAge(CalculateAge(dateBirth));
                        } else {
                          setAge("-");
                        }
                      }}
                    />
                  </div>
                </Col>

                <Col xs={12} md={4}>
                  <Form.Label className="field-label">Idade</Form.Label>
                  <input
                    disabled={true}
                    style={{
                      background: "#468DFF1A",
                      color: Colors.brand_blue,
                      border: "none",
                      fontWeight: "bold",
                    }}
                    className="default-input text-center"
                    type="text"
                    placeholder="-"
                    value={age}
                  />
                </Col>
              </Row>
              <div className="mt-4">
                <Form.Label className="field-label">
                  Superfície de suporte
                </Form.Label>
                <Select
                  captureMenuScroll={false}
                  className="drop-up"
                  noOptionsMessage={() => "Sem opções"}
                  classNamePrefix="react-select"
                  placeholder={"Selecionar superfície"}
                  options={listBeds}
                  isClearable={false}
                  onChange={(item) => setBed(item)}
                />

                {bed == 6 && (
                  <input
                    className="default-input mt-3"
                    type="text"
                    placeholder="Digite aqui o tipo da superfície de suporte"
                    value={otherBed}
                    onChange={(e) => setOtherBed(e.target.value)}
                  />
                )}
              </div>

              <div className="mt-4">
                <Form.Label className="field-label">Comorbidades</Form.Label>
                <Select
                  captureMenuScroll={false}
                  className="drop-up"
                  noOptionsMessage={() => "Sem opções"}
                  classNamePrefix="react-select"
                  placeholder="Selecionar comorbidades"
                  closeMenuOnSelect={false}
                  options={listComorbidities}
                  isMulti
                  styles={colourStyles}
                  isClearable={false}
                  value={FilterOther(comorbidities)}
                  onChange={(array) => {
                    //Mostra o input caso marque a opção "Outros"
                    if (array != null) {
                      var found = array.find((item) => item.label == "Outros");
                      if (found != undefined || newComorbiditie != "")
                        setHasOther(true);
                      else setHasOther(false);
                    }

                    setComorbidities(
                      array == null
                        ? []
                        : array.filter((i) => i.label !== "Outros")
                    );
                  }}
                />

                {hasOther && (
                  <input
                    className="default-input mt-3"
                    type="text"
                    placeholder="Digite aqui a comorbidade"
                    value={newComorbiditie}
                    onChange={(e) => setNewComobiditie(e.target.value)}
                  />
                )}
              </div>

              <div className="mt-4">
                <Form.Label className="field-label">
                  Estados situacionais
                </Form.Label>
                <Select
                  captureMenuScroll={false}
                  className="drop-up"
                  noOptionsMessage={() => "Sem opções"}
                  classNamePrefix="react-select"
                  placeholder="Selecionar estados situacionais"
                  closeMenuOnSelect={false}
                  options={listSituationalStates}
                  isMulti
                  styles={colourStyles}
                  isClearable={false}
                  value={FilterOther(situationalStates)}
                  onChange={(array) => {
                    //Mostra o input caso marque a opção "Outros"
                    if (array != null) {
                      var found = array.find((item) => item.label == "Outros");
                      if (found != undefined || newSituationalState != "")
                        setHasOtherSituationalState(true);
                      else setHasOtherSituationalState(false);
                    }

                    setSituationalStates(
                      array == null
                        ? []
                        : array.filter((i) => i.label !== "Outros")
                    );
                  }}
                />

                {hasOtherSituationalState && (
                  <input
                    className="default-input mt-3"
                    type="text"
                    placeholder="Digite aqui o estado situacional"
                    value={newSituationalState}
                    onChange={(e) => setNewSituationalState(e.target.value)}
                  />
                )}
              </div>

              <div style={{ height: 20 }} />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-end align-items-center">
          <Col xs={4} md={3} lg={3} className="px-0 d-flex justify-content-end">
            <button
              className="modal-footer-btn mr-0 btn-cancel"
              onClick={() => setShow(false)}
            >
              Cancelar
            </button>
          </Col>
          <Col xs={4} md={3} lg={3} className="px-0">
            <button
              disabled={
                (VerifyFields() == true ? false : true) || loadingBtn === "flex"
              }
              className="mb-0 modal-footer-btn btn-confirm"
              style={{
                backgroundColor:
                  VerifyFields() == true
                    ? Colors.brand_pink
                    : Colors.lighter_gray,
              }}
              onClick={() => {
                if (!userWithoutPermission(user)) {
                  RegisterPatient();
                  return false;
                }
                setShowPw(true);
              }}
            >
              <p
                className="mb-0"
                style={{
                  color: VerifyFields() == true ? "white" : Colors.dark_gray,
                  display: loadingBtn == "none" ? "flex" : "none",
                }}
              >
                Confirmar
              </p>

              <Spinner
                style={{ display: loadingBtn }}
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddPatient;
